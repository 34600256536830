export const NAV_STICKY = 'NAV_STICKY';
export const NAV_NORMAL = 'NAV_NORMAL';
export const SHOWCASE_STICKY = 'SHOWCASE_STICKY';
export const SHOWCASE_NORMAL = 'SHOWCASE_NORMAL';
export const RESUME_STICKY = 'RESUME_STICKY';
export const RESUME_NORMAL = 'RESUME_NORMAL';

export const ABOUT_ACTIVE = 'ABOUT_ACTIVE';
export const SKILLS_ACTIVE = 'SKILLS_ACTIVE';
export const EXPERIENCE_ACTIVE = 'EXPERIENCE_ACTIVE';
export const EDUCATION_ACTIVE = 'EDUCATION_ACTIVE';

export const SHOW_DETAILS = 'SHOW_DETAILS';
export const HIDE_DETAILS = 'HIDE_DETAILS';